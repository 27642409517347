import { FC } from 'react';
import { ConsumerData, EditFormProps } from '../data';
import { capitalize, Theme, Tooltip, Typography } from '@mui/material';
import EnablingList from './EnablingList';

const styles = {
    title: (theme: Theme) => ({
        color: theme.palette.text.primary,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontSize: '16px',
        fontWeight: 500,
    }),
}

const AccountableLoans: FC<Pick<
    EditFormProps,
    'initial'
    | 'data'
    | 'setData'
    | 'categories'
    | 'readonly'
>> = ({
    initial,
    data,
    setData,
    categories,
    readonly,
}) => {
    return <EnablingList
        items={ categories.map(category => ({
            id: category.id,
            title: <Tooltip title={ capitalize(category.name) }>
                <Typography sx={ styles.title }>
                    { category.name }
                </Typography>
            </Tooltip>
        })) }
        selectedIds={ new Set((data.accountableLoanCategories || [])
            .filter(c => c.enabled)
            .map(c => c.category!.id)
        ) }
        setSelectedIds={ selectedIds => setData({
            ...data,
            accountableLoanCategories: (() => {
                const categories =
                    [...data.accountableLoanCategories || []];

                for (const id of selectedIds) {
                    if (!categories.find(i => i.category!.id === id)) {
                        categories.push({ id, category: { id } } as any);
                    }
                }

                const updated = categories.map(c =>
                    ({ ...c, enabled: selectedIds.has(c.category!.id) }),
                );

                const getSortedEnabled = (
                    categories: ConsumerData['accountableLoanCategories'],
                ) => categories
                    ?.filter(c => c.enabled)
                    .map(c => c.category!.id)
                    .sort((a, b) => a.localeCompare(b)) || [];

                const sortedInitial =
                    getSortedEnabled(initial.accountableLoanCategories);
                const sortedUpdated = getSortedEnabled(updated);

                return sortedInitial.length === sortedUpdated.length &&
                    sortedInitial.every((c, i) => c === sortedUpdated[i])
                        ? initial.accountableLoanCategories
                        : updated;
            })()
        }) }
        readonly={ readonly }
    />;
};

export default AccountableLoans;
